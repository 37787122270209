import React from 'react'
import styled from 'styled-components'
import { Tabs, Tab } from '@material-ui/core'
import { Build, Archive } from '@material-ui/icons'

import ClaimItem from './ClaimItem'

export interface IAdjuster {
	name: string
	phone: string
	company: string
	lastMessage?: string
}

export interface IClaim {
	closed?: boolean
	insuredName: string
	lossLocation: string
	company: string
	claimNo: string
	dateOfLoss: Date
	assignedAdjuster: IAdjuster
	stepsCompleted: number
}

const claimsBase: IClaim[] = [
	{
		insuredName: 'Frame, Matthew',
		lossLocation: '3741 Kennoway, Frisco, TX 75034',
		company: 'USAA',
		claimNo: '78087',
		dateOfLoss: new Date('2019-08-07'),
		stepsCompleted: 3,
		assignedAdjuster: {
			name: 'Gary Hamilton',
			phone: '(972) 836-8823',
			company: 'USAA',
			lastMessage: 'Yes I doubled checked the mortgage company is correct'
		}
	},
	{
		closed: true,
		insuredName: 'Nassim, Derek',
		lossLocation: '1842 Alder Rd, Frisco, TX 75030',
		company: 'USAA',
		claimNo: '15125',
		dateOfLoss: new Date('2019-08-02'),
		stepsCompleted: 7,
		assignedAdjuster: {
			name: 'Gary Hamilton',
			phone: '(972) 836-8823',
			company: 'USAA',
			lastMessage: 'Claim has been closed'
		}
	},
	{
		closed: true,
		insuredName: 'Matthews, Christina',
		lossLocation: '83921 Avenida De Los Lirios, Frisco, TX 75032',
		company: 'USAA',
		claimNo: '62632',
		dateOfLoss: new Date('2019-08-04'),
		stepsCompleted: 6,
		assignedAdjuster: {
			name: 'Gary Hamilton',
			phone: '(972) 836-8823',
			company: 'USAA',
			lastMessage: 'Claim has been closed'
		}
	}
]

const Claims = () => {
	const [claims, setClaims] = React.useState(claimsBase)
	const _updateClaim = (ind: number) => (c: Partial<IClaim>) => {
		const c2 = [...claims]
		c2[ind] = { ...claims[ind], ...c }
		setClaims(c2)
	}
	const [tab, setTab] = React.useState(0)
	const handleTabChange = (e: React.ChangeEvent<{}>, newVal: number) =>
		setTab(newVal)

	return (
		<JobsContainer>
			<Tabs
				style={{ marginBottom: 24 }}
				value={tab}
				onChange={handleTabChange}
				centered
			>
				<Tab
					icon={<Build />}
					style={{ color: 'white' }}
					label={`Open Claims`}
				/>
				<Tab
					icon={<Archive />}
					style={{ color: 'white' }}
					label={`Closed Claims`}
				/>
			</Tabs>
			{claims
				.filter(c => (tab === 0 ? !c.closed : !!c.closed))
				.map((claim, ii) => (
					<ClaimItem claim={claim} key={ii} updateClaim={_updateClaim(ii)} />
				))}
		</JobsContainer>
	)
}

export default Claims

const JobsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 12px 40px 40px 40px;
	width: auto;
	height: auto;
	min-height: 100%;

	@media (max-width: 850px) {
		padding: 16px;
		padding-top: 16px;
		padding-bottom: 66px;
	}
`
