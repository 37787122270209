import * as React from 'react'

import ClaimsSidebar from '../components/claimsSidebar'
import SEO from '../components/seo/seo'
import Claims from '../components/claims'

const IndexPage = () => (
	<ClaimsSidebar>
		<SEO title="Claims" keywords={['claims']} />
		<Claims />
	</ClaimsSidebar>
)

export default IndexPage
