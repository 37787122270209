import React from 'react'
import styled from 'styled-components'

import { IClaim } from '.'
import Swal from 'sweetalert2'
import { TransparentCard } from '../ui/TransparentCard'
import {
	Typography,
	Checkbox,
	FormControlLabel,
	Button,
	GridList,
	GridListTile
} from '@material-ui/core'
import AdjusterItem from './AdjusterItem'
import { navigate } from 'gatsby'
import ResponsiveModal from '../ui/ResponsiveModal'
import EditableText from '../ui/EditableText'
import Uploader from '../BidChat/Uploader'
import NewHomeModal from '../modals/newHomeModal'
import BidChat from '../BidChat'
import { BidMessage } from '../../apollo/generated'
import { getTokenUserId } from '../../utils/client'

interface Props {
	claim: IClaim
	updateClaim: (c: Partial<IClaim>) => void
}

const claimMessages: () => BidMessage[] = () => {
	const userId = getTokenUserId()
	return [
		{
			id: '1234123',
			text: 'Hey are all of these items accurate?',
			createdAt: new Date(Date.now() - 8 * 60e3 * 60 * 24 - 14e3),
			sender: {
				id: 'asdf',
				email: 'Chris'
			} as any
		},
		{
			id: '1234',
			text: 'Yes I double checked the mortgage company is correct',
			createdAt: new Date(Date.now() - 8 * 60e3 * 60 * 24),
			sender: {
				id: userId,
				email: 'Joe'
			} as any
		}
	]
}

const ClaimItem = ({ claim, updateClaim }: Props) => {
	const [modalName, setModalName] = React.useState<string | null>(null)
	const promiseModal = async (n: string | null) => setModalName(n)
	const setModalAndStep = async (f: () => void, step: number) => {
		const v: any = await f()
		if (v && (v.value === false || v.canceled === true)) {
			return
		}
		updateClaim({ stepsCompleted: step })
	}
	return (
		<Container>
			<Details>
				<Info>
					<EditableText
						onChange={insuredName => updateClaim({ insuredName })}
						title="Insured Name"
						value={claim.insuredName}
					>
						<Typography>
							<b>Insured: </b>
							{claim.insuredName}
						</Typography>
					</EditableText>
					<EditableText
						onChange={lossLocation => updateClaim({ lossLocation })}
						title="Loss Location"
						value={claim.lossLocation}
					>
						<Typography>
							<b>Loss Location: </b>
							{claim.lossLocation}
						</Typography>
					</EditableText>
					<EditableText
						onChange={company => updateClaim({ company })}
						title="Company"
						value={claim.company}
					>
						<Typography>
							<b>Company: </b>
							{claim.company}
						</Typography>
					</EditableText>
					<EditableText
						onChange={claimNo => updateClaim({ claimNo })}
						title="Claim Number"
						value={claim.claimNo}
					>
						<Typography>
							<b>Claim Number: </b>
							{claim.claimNo}
						</Typography>
					</EditableText>
					<EditableText
						type="date"
						onChange={dateOfLoss =>
							updateClaim({ dateOfLoss: new Date(dateOfLoss) })
						}
						title="Date of Loss"
						value={claim.dateOfLoss.toString()}
					>
						<Typography>
							<b>Date of Loss: </b>
							{new Date(claim.dateOfLoss).toLocaleDateString()}
						</Typography>
					</EditableText>
					<Button
						style={{
							alignSelf: 'flex-start',
							padding: '7px 14px',
							marginTop: '10px'
						}}
						size="small"
						onClick={() => navigate('/jobs/claim-new')}
					>
						Adjust Xactimate In Real Time
					</Button>
				</Info>
				<Progress>
					{[
						[
							'Measurements Ordered',
							() => setModalAndStep(() => promiseModal('property'), 0)
						],
						[
							'Claim Photos (4)',
							() => setModalAndStep(() => promiseModal('images'), 1)
						],
						[
							'Initial Xactimate',
							() => setModalAndStep(() => promiseModal('pdf'), 2)
						],
						[
							'Initial Payment (0)',
							() => setModalAndStep(() => promiseModal('upload'), 3)
						],
						[
							'Revised Xactimate',
							() => setModalAndStep(() => promiseModal('pdf'), 4)
						],
						[
							'Supplement Payment (0)',
							() => setModalAndStep(() => promiseModal('upload'), 5)
						],
						[
							'Final Payment (0)',
							() => setModalAndStep(() => promiseModal('upload'), 6)
						],
						[
							'Claim Closed',
							() =>
								setModalAndStep(
									() =>
										Swal.fire({
											title: 'Close Claim',
											text: 'Are you sure you want to close this claim?',
											type: 'question',
											showCancelButton: true,
											reverseButtons: true
										}),
									7
								)
						]
					].map(([c, oc], ii) => (
						<FormControlLabel
							key={ii}
							control={
								<Checkbox
									color="primary"
									checked={claim.stepsCompleted >= ii}
									style={{ padding: 2, color: 'white', marginLeft: 4 }}
								/>
							}
							onChange={() => oc()}
							label={c}
							labelPlacement="start"
						/>
					))}
				</Progress>
			</Details>
			<Adjusters onClick={() => setModalName('chat')}>
				<AdjusterItem adjuster={claim.assignedAdjuster} />
			</Adjusters>
			<ResponsiveModal open={!!modalName} onClose={() => setModalName(null)} overflow={modalName === 'property' ? 'inherit': undefined}>
				{modalName === 'chat' && (
					<BidChat
						bidId=""
						skipLoading
						manuallySuppliedMessages={claimMessages()}
					/>
				)}
				{modalName === 'images' && (
					<ImagesModal open onClose={() => setModalName(null)} />
				)}
				{modalName === 'upload' && (
					<Uploader open onClose={() => setModalName(null)} />
				)}
				{modalName === 'property' && (
					<NewHomeModal skipCC open onClose={() => setModalName(null)} />
				)}
				{modalName === 'pdf' && (
					<iframe
						src="https://drive.google.com/file/d/164AvCCohWEbmGyKxLRgV6x2XO73YoN3C/preview"
						width="720"
						height="480"
					/>
				)}
			</ResponsiveModal>
		</Container>
	)
}

export default ClaimItem

const urls = [
	'https://i.imgur.com/XoALRgE.jpg',
	'https://i.imgur.com/0liDkn8.jpg',
	'https://i.imgur.com/igM3Z6V.jpg',
	'https://i.imgur.com/ssQg9uX.jpg',
	'https://i.imgur.com/9V5zQ9d.jpg'
]

const ImagesModal = () => (
	<GridList cellHeight={320} cols={4}>
		{urls.map(url => (
			<GridListTile key={url} cols={2}>
				<img src={url} alt="test" />
			</GridListTile>
		))}
	</GridList>
)

const Container = styled(TransparentCard)`
	min-height: 320px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 32px;
	background-color: #2c3e50;
	* {
		color: white;
		border-color: white;
		line-height: 2;
	}

	@media (max-width: 850px) {
		padding: 10px;
	}
`
const Details = styled.div`
	display: flex;
	justify-content: space-between;
`

const Info = styled.div`
	display: flex;
	flex-direction: column;
`

const Progress = styled.div`
	&& {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-top: -4px;

		> * {
			text-align: right;
		}
	}
`

const Adjusters = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	max-height: 440px;
	overflow-y: auto;
	margin: 12px -22px -22px -22px;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.15);

	@media (max-width: 850px) {
		> * {
			text-align: left;
			padding: 4px;
		}
	}
`
