import React from 'react'
import styled from 'styled-components'
import '../ui/sidebar/layout.css'
import StaticDrawerWrapper from '../ui/sidebar/staticDrawerWrapper'

import FallbackAvatar from '../ui/FallbackAvatar'

import HomeSelector from '../jobSidebar/HomeSelector'
import ClaimsSidebarRoutes from './ClaimsSidebarRoutes'
import { useGetUserProperties } from '../../apollo/queries'
import Loading from '../ui/Loading'
import { Typography } from '@material-ui/core'

const ClaimsSidebar = ({ children }: { children: React.ReactNode }) => {
	const { data, loading } = useGetUserProperties()

	if (loading)
		return (
			<StaticDrawerWrapper sideArea={() => <Loading />}>
				{children}
			</StaticDrawerWrapper>
		)

	const {
		homeowner: { properties, user, id }
	} = data

	return (
		<>
			<StaticDrawerWrapper
				sideArea={({ closeDrawer }) => (
					<>
						<HomeSelector closeDrawer={closeDrawer} />
						<ClaimsSidebarRoutes />
						<AdjusterProfile>
							<FallbackAvatar
								src="https://i.imgur.com/vSSMwkI.png"
								name="Joe Carter"
								size={68}
							/>
							<Texts>
								<Typography
									variant="h5"
									style={{ fontSize: 16, fontWeight: 500 }}
								>
									Joe Carter
								</Typography>
								<Typography
									variant="subtitle1"
									style={{ fontVariant: 'italic', fontSize: 12 }}
								>
									<i>Independent Adjuster</i>
								</Typography>
								<Typography
									variant="subtitle1"
									style={{ fontVariant: 'italic', fontSize: 12 }}
								>
									<i>E.A. Renfroe</i>
								</Typography>
							</Texts>
						</AdjusterProfile>
					</>
				)}
			>
				{children}
			</StaticDrawerWrapper>
		</>
	)
}

export default ClaimsSidebar

const AdjusterProfile = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 74px;
	left: 0;
	right: 0;
`
const Texts = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 8px;
	> * {
		color: white;
	}
`
