import React from 'react'
import styled from 'styled-components'
import { Button, List } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SidebarItem from '../ui/sidebar/SidebarItem'
import ResponsiveModal from '../ui/ResponsiveModal'
import NewHomeModal from '../modals/newHomeModal'

const ClaimsSidebarRoutes = () => {
	const [modal, setModal] = React.useState(false)
	return (
		<>
			<JobsContainer>
				<List>
					<SidebarItem
						url="/claims"
						name="My Claims"
						iconName="home"
						partiallyActive={false}
					/>
					<SidebarItem
						url="https://helpcenter.fairmarkethome.com"
						name="Help Center"
						iconName="help"
					/>
				</List>
				<NewJobLink>
					<NewJobButton onClick={() => setModal(true)}>
						<AddIcon style={{ marginRight: 8 }} />
						Create New Claim
					</NewJobButton>
				</NewJobLink>
			</JobsContainer>
			<ResponsiveModal open={modal} onClose={() => setModal(false)} overflow={'inherit'}>
				<NewHomeModal onClose={() => setModal(false)} />
			</ResponsiveModal>
		</>
	)
}
export default ClaimsSidebarRoutes

const NewJobButton = styled(Button).attrs({
	variant: 'outlined',
	size: 'small'
})`
	&& {
		margin-top: 8px;
		text-transform: none;
	}
`

const NewJobLink = styled.div`
	display: flex;
	align-items: stretch;
	flex-direction: column;
	text-decoration: none;
	margin: 8px 20px;
	&.active > * {
		background-color: rgba(255, 255, 255, 0.12);
	}
`

const JobsContainer = styled.div`
	padding: 22px 10px;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
`
