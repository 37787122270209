import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { IAdjuster } from '.'
import FallbackAvatar from '../ui/FallbackAvatar'
import { Chat } from '@material-ui/icons'

interface Props {
	adjuster: IAdjuster
}

const AdjusterItem = ({ adjuster }: Props) => (
	<BidContainer>
		<FallbackAvatar
			src="https://jaysoriano.com/wp-content/uploads/2018/04/P8110569-Edit.jpg"
			name={adjuster.name}
			size={40}
		/>
		<BidText style={{ marginRight: 48 }}>
			<BidItemTitle>{adjuster.name}</BidItemTitle>
			<BidCompInfo>{adjuster.phone}</BidCompInfo>
			<BidItemDesc>{adjuster.company}</BidItemDesc>
		</BidText>
		<BidText
			style={{
				flex: 1
			}}
		>
			<BidItemTitle>{adjuster.lastMessage}</BidItemTitle>
		</BidText>
		{adjuster.lastMessage && <Chat color="primary" />}
		<BidArrow />
	</BidContainer>
)

export default AdjusterItem

const BidText = styled.div`
	display: flex;
	flex: 0;
	flex-direction: column;
	justify-content: center;
	margin-left: 16px;

	@media (min-width: 1022px) {
		margin-right: 12px;
		max-width: Calc(100% - 128px);
		position: relative;

		> * {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
`

type Styled<T> = T extends React.ComponentType<infer P>
	? React.ComponentType<Partial<P>>
	: T

const BidItemTitle = styled<Styled<typeof Typography>>(Typography)`
	&& {
		font-size: 16px;
		opacity: 0.87;
		color: #fff;
		transition: opacity 0.3s ease-out;
		letter-spacing: 0.15px;
		font-weight: 500;
	}
`

const BidItemDesc = styled<Styled<typeof Typography>>(BidItemTitle)`
	&& {
		font-size: 12px;
		font-weight: 300;
		opacity: 0.65;
		color: #fff;
		transition: opacity 0.3s ease-out;
	}
`

const BidCompInfo = styled<Styled<typeof Typography>>(BidItemDesc)`
	&& {
		opacity: 1;
		font-weight: bold;
		font-size: 11px;
	}
`

const BidContainer = styled.div`
	&,
	& * {
		line-height: 1.5 !important;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 64px;
	padding-top: 9px;
	padding-bottom: 9px;
	padding-right: 10px;
	padding-left: 10px;
	width: 100%;
	margin-left: -2px;
	margin-right: 2px;
	cursor: pointer;
	&:not(:first-child) {
		border-top: 0.5px solid rgba(255, 255, 255, 0.3);
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	@media (min-width: 850px) {
		&:hover {
			${BidItemTitle}, ${BidItemDesc} {
				opacity: 1;
			}
		}
	}
`

const BidPrice = styled<Styled<typeof Typography>>(Typography)`
	&& {
		font-size: 20px;
		color: #fff;
		letter-spacing: 0.19px;
		text-align: right;
		margin-left: 16px;
		font-weight: semibold;
	}
`

const BidArrow = styled(ChevronRight)`
	&& {
		color: rgba(50, 50, 255, 0.7);
		opacity: 0.65;
		font-size: 24px;
		right: 0;
		margin-right: -12px;
		margin-left: 12px;
		margin-top: -2px;
	}
` as typeof ChevronRight
